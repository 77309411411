import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
// import FootComp from './Components/Footer';
import SignUp from './Pages/SignUp';
import Shop from './Pages/Shop';
import Send from './Pages/Send';
import Leaderboard from './Pages/Leaderboard';
import Create from './Pages/CreateItem';

export function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Home/>
                    {/* <FootComp/> */}
                </Route>
                <Route path="/shop">
                    
                    <Shop/>

                </Route>
                <Route path="/send">
                    
                    <Send/>

                </Route>
                <Route path="/team">
                    


                </Route>
                <Route path="/create">
                    <Create/>
                </Route>

                <Route path="/leaderboard">
                    <Leaderboard/>
                </Route>

                <Route path="/login">
                    <Login/>
                </Route>

                <Route path="/signup">
                    <SignUp/>
                </Route>
            </Switch>
        </BrowserRouter>

    );
}

