import React, {Component} from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import ReactDOM from 'react-dom';
import './style.css'

class Leaderboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
        alert: false,
        alertvariant: '',
        alertContent: '',
    };

  }
  

  async componentDidMount () {
        if(sessionStorage.getItem('token') != null) {
            const students = await this.getStudents();
            let itemsList = [];
            for (let i = 0; i < students.length; i++) {
                    itemsList.push(
                        <div key={i}>
                            <Col style={{paddingBottom: 30}}>
                                <Card text='white' bg="dark" className='cardhover colored' style={{border: 'none'}}>
                                    <Card.Body>
                                        <Row className='align-items-center'>
                                            <Col xs={{span: 'auto'}}>
                                                <Card.Title style={{color: "white"}}>{i+1}. {students[i].email}</Card.Title>
                                            </Col>
                                            <Col xs={{span: 'auto'}} className='ml-auto'>
                                                <p>Form {students[i].grade}</p>
                                            </Col>
                                            <Col xs={{span: 'auto'}} className='ml-auto'>
                                                <p className=''>Balance: {students[i].balance} OBS</p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </div>
                    )
            }
            ReactDOM.render(itemsList, document.getElementById('shop'))
        }  
    }

    async getStudents() {
        const leaderboard = await fetch('https://obscoin.herokuapp.com/api/leaderboard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: sessionStorage.getItem('token')
            })
        }).then((res) => res.json());
        console.log(leaderboard);
        if(leaderboard.status === 'ok') {
            const students = leaderboard.orderedStudentArray
            return students;
        }
    }

    async buyItem (itemname) {
        this.setState({alert: true, alertvariant: "success", alertContent: `Purchasing ${itemname}`})
        // const spend = await fetch('https://obscoin.herokuapp.com/api/spend', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         token: sessionStorage.getItem('token'),
        //         itemname: itemname
        //     })
        // }).then((res) => res.json());
        // const timeOut = setTimeout(() => {
        //     this.setState({alert: false, alertvariant: "", alertContent: ''})
        // }, 3000)
        // console.log(spend)
    }

    render() {
        if(sessionStorage.getItem('token') != null) {
            return ( 
                <div className="App">
                    <div>
                        <Container style={{textAlign: "left", paddingTop: '10%'}} className='justify-content-center'>
                            <Col key={'title'}>
                                <h1>Leaderboard</h1>  <hr style={{borderImage: "linear-gradient(to right, rgba(200, 30, 200, 0.7), rgba(30, 150, 250, 0.7))", borderImageSlice: '5'}} />
                            </Col>
                            <div id='shop'>
                            </div>
                        </Container>
                    </div>
                    <div className='fixed-bottom'>
                        <Container  className='rounded'>
                            <Alert show={this.state.alert} variant={this.state.alertvariant} className="align-items-center justify-content-center">
                                <p>{this.state.alertContent}</p>
                            </Alert>
                        </Container>
                    </div>
                </div>
            )
        } else {
            return (
                <Container  className='rounded horizontalcenter'>
                    <Alert show={true} variant='danger' className="align-items-center justify-content-center">
                        <p>Please log in to view this page</p>
                    </Alert>
                </Container>

            )
        }
    }
}

export default Leaderboard;