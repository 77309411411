import React, {Component} from "react";
import { Alert, Button, Card, Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import './style.css'

class Send extends Component {

  constructor(props) {
    super(props);
    this.state = {
        alert: false,
        alertvariant: '',
        alertContent: '',
        email: '',
        amount: 10,
    };

    this.send = this.send.bind(this);
  }
  

    async componentDidMount () {

    }

    async send () {
        this.setState({alert: true, alertvariant: "success", alertContent: `Sending ${this.state.amount} OBS to ${this.state.email}`})

        const spend = await fetch('https://obscoin.herokuapp.com/api/transaction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: sessionStorage.getItem('token'),
                receiver: this.state.email,
                amount: this.state.amount
            })
        }).then((res) => res.json());
        const timeOut = setTimeout(() => {
            this.setState({alert: false, alertvariant: "", alertContent: ''})
        }, 3000)
        console.log(spend)
        if(spend.status === 'error') {
            this.setState({alert: true, alertvariant: "danger", alertContent: spend.error})
        }
        
    }

    async buyItem (itemname) {
        this.setState({alert: true, alertvariant: "success", alertContent: `Purchasing ${itemname}`})
        const spend = await fetch('https://obscoin.herokuapp.com/api/spend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: sessionStorage.getItem('token'),
                itemname: itemname
            })
        }).then((res) => res.json());
        const timeOut = setTimeout(() => {
            this.setState({alert: false, alertvariant: "", alertContent: ''})
        }, 3000)
        console.log(spend)
    }

    render() {
        if(sessionStorage.getItem('token') != null) {
            return ( 
                <div className="App">
                    <div>
                        <Container style={{textAlign: "left", paddingTop: '10%'}} className='justify-content-center'>
                            <Col key={'title'}>
                                <h1>Send</h1>  <hr style={{borderImage: "linear-gradient(to right, rgba(200, 30, 200, 0.7), rgba(30, 150, 250, 0.7))", borderImageSlice: '5'}} />
                            </Col>
                                <Col style={{paddingBottom: 30}}>
                                    <Card text='white' bg="dark" className='colored' style={{border: 'none'}}>
                                        <Card.Body>
                                            <Row className='align-items-center'>
                                                <Col xs={{span: 'auto'}} md={{span: 4}}>
                                                    <input className="form-control form-control-user inputfocus" type="email" placeholder="To:" name="email" onChange={ (e) => {this.setState({email: e.target.value})}} required/>
                                                </Col>
                                                <Col xs={{span: 'auto'}} md={{span: 4}}>
                                                    <DropdownButton className='inputfocus' title={`Amount: ${this.state.amount}`} onSelect={ (e) => { this.setState({amount: e})}}>
                                                        <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                                                        <Dropdown.Item eventKey={20}>20</Dropdown.Item>
                                                        <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                                                    </DropdownButton>
                                                </Col>
                                                <Col xs={{span: 'auto'}} md={{span: 4}}>
                                                    <Button className="border-0 btn btn-primary d-block btn-user w-100 coloredreverse cardhover" type="submit" onClick={this.send}>Send</Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                        </Container>
                    </div>
                    <div className='fixed-bottom'>
                        <Container  className='rounded'>
                            <Alert show={this.state.alert} variant={this.state.alertvariant} className="align-items-center justify-content-center">
                                <p>{this.state.alertContent}</p>
                            </Alert>
                        </Container>
                    </div>
                </div>
            )
        } else {
            return (
                <Container  className='rounded horizontalcenter'>
                    <Alert show={true} variant='danger' className="align-items-center justify-content-center">
                        <p>Please log in to view this page</p>
                    </Alert>
                </Container>

            )
        }
    }
}

export default Send;