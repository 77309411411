import './App.css';
import NavComp from './Components/Navbar';
import { Routes } from './Routes';
import { BrowserRouter as Router, } from "react-router-dom";
import UserContext from './context/UserContext';
import getUserProfile from './Components/getUserProfile';
import { Component } from 'react';

class App extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      category: '',
      grade: 0,
      balance: {
        amount: 0,
        symbol: '',
      }
    };
  }

  async componentDidMount() {
    const result = await getUserProfile();
    if(result.status === 'ok') {
      this.setState({ email: result.data.profile.email, 
                      category: result.data.profile.category, 
                      grade: result.data.profile.grade, 
                      balance: {
                        amount: parseInt(result.data.profile.coins), 
                        symbol: "OBS",
                      }, 
                    })
    }
  }

  render() {
    const email = this.state.email;
    const category = this.state.category;
    const grade = this.state.grade;
    const balance = this.state.balance;
    return (
      <div className='App'>
      <Router>
        <UserContext.Provider value={{email, category, grade, balance}}>
          <NavComp/>
          <Routes />
        </UserContext.Provider>
      </Router>
    </div>
    );
  }
}
export default App;

